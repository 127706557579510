import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=566fd600&"
import script from "./Sidebar.vue?vue&type=script&lang=ts&"
export * from "./Sidebar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppBackdropOverlay: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-2/_work/11/a/NuxtApp/components/app/BackdropOverlay.vue').default})
